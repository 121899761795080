import React, {Fragment, useEffect, useState} from 'react';
import Autocomplete from "react-google-autocomplete";
import Constants from "../../../common/Constants";
import Utils from "../../../common/Utils";
import {moengageTrackEvent} from "../../../moengage";

const propTypes = {};

const SearchBox = (props) => {
    const [inputPlace, setInputPlace] = useState(null);
    const [showMessage, setShowMessage] = useState(false);

    const handleAddPlace = (place) => {
        setInputPlace(place)
        setShowMessage(false)
    }

    useEffect(() => {
        if (inputPlace) {
            reRender()
        }
    }, [inputPlace])

    const reRender = () => {
        let input = props.inputSearch;
        let resultList = [];
        const place = inputPlace ?? null
        if (place) {
            const lat = place.geometry?.location?.lat();
            const long = place.geometry?.location?.lng();
            if (lat && long){
                const list = [...props.listSite].filter((item) =>
                    item?.ms_addresses?.length > 0
                        ? (item.distance =
                            Utils.getDistanceFromLatLonInKm(
                                lat,
                                long,
                                item?.ms_addresses[0]?.ms_latitude,
                                item?.ms_addresses[0]?.ms_longitude,
                            ))
                        : (item.distance =
                            Utils.getDistanceFromLatLonInKm(
                                lat,
                                long,
                                item?.ms_addresses?.map(
                                    (e) => e?.ms_latitude,
                                ),
                                item?.ms_addresses?.map(
                                    (e) => e?.ms_longitude,
                                ),
                            )),
                );
                list.sort((a, b) => a.distance - b.distance);
                input = place.formatted_address;
                resultList = [...list.slice(0, 10)];
                // set value
                props.setListAcademy(resultList);
                props.setShowListAcademy(false);
                props.setSearched(true);
                props.setTextResult(input);

                moengageTrackEvent('Nearest_Academy_Searched', {
                    "Pincode": null,
                    "Address" : place.formatted_address,
                })
            }
        } else {
            if (window.localStorage.getItem('currentLocationLongitude') && window.localStorage.getItem('currentLocationLatitude'))
            {
                const lat = window.localStorage.getItem('currentLocationLatitude');
                const long = window.localStorage.getItem('currentLocationLongitude');
    
                if (lat && long){
                    const list = [...props.listSite].filter((item) =>
                        item?.ms_addresses?.length > 0
                            ? (item.distance =
                                Utils.getDistanceFromLatLonInKm(
                                    lat,
                                    long,
                                    item?.ms_addresses[0]?.ms_latitude,
                                    item?.ms_addresses[0]?.ms_longitude,
                                ))
                            : (item.distance =
                                Utils.getDistanceFromLatLonInKm(
                                    lat,
                                    long,
                                    item?.ms_addresses?.map(
                                        (e) => e?.ms_latitude,
                                    ),
                                    item?.ms_addresses?.map(
                                        (e) => e?.ms_longitude,
                                    ),
                                )),
                    );
                    list.sort((a, b) => a.distance - b.distance);
                    resultList = [...list.slice(0, 10)];
                    props.setListAcademy(resultList);
                    props.setShowListAcademy(false);
                    props.setSearched(true);
    
                    moengageTrackEvent('Nearest_Academy_Searched', {
                        "Pincode": null,
                        "Address" : input,
                    })
                }
            }
        }
    }

    return (
        <Fragment>
            <div className="form-input">
                <Autocomplete
                    id="inputSearch"
                    apiKey={process.env.NEXT_PUBLIC_GOOOLE_API_KEY}
                    style={{ width: "100%" }}
                    onChange={(event) => {
                        props.setInputSearch(event.target.value);
                        props.setListAcademy([]);
                        props.setShowListAcademy(true);
                        setInputPlace(null)
                    }}
                    onPlaceSelected={handleAddPlace}
                    options={{
                        types: ["(regions)"],
                        componentRestrictions: { country: "uk" },
                    }}
                    placeholder={
                        props.country === Constants.US
                            ? 'Type to enter your address or ZIP code'
                            : 'Type to enter your address, town or postcode'
                    }
                    defaultValue={props.inputSearch}
                />
                <button
                    onClick={() => {
                        reRender()
                        if (props.inputSearch && !inputPlace) {
                            setShowMessage(true)
                        } else {
                            setShowMessage(false)
                        }

                    }}>
                    {props.searched ? 'FIND' : 'GO'}
                </button>
            </div>

            {
                showMessage ? (
                    <p style={{fontSize: '14px', fontWeight: '400', color: '#ff6b00'}}>Please select address from Google Map suggestion in dropdown.</p>
                ) : null
            }
        </Fragment>
    );
};

SearchBox.propTypes = propTypes;
export default SearchBox;
