
const moengageTrackEvent = (name, attributes) => {
    if (window !== undefined) {
        // @ts-ignore
        const localMoengage = window?.Moengage;
        if (localMoengage !== undefined) {
            localMoengage.track_event(name.toString(), attributes);
        }
    }
};

const moengageParentInit = (data) => {
    // push info to moengage
    if (window !== undefined) {
        // @ts-ignore
        const localMoengage = window?.Moengage;
        if (localMoengage !== undefined) {
            // eslint-disable-next-line no-undef
            localMoengage.add_unique_user_id(data?.email).then(() => {
                localMoengage.add_first_name(data?.first_name ?? "");
                localMoengage.add_last_name(data?.last_name ?? "");
                localMoengage.add_email(data?.email ?? "");
                localMoengage.add_mobile(data?.phone ?? "");
                localMoengage.add_user_name(
                    `${data?.first_name} ${data?.last_name}` ?? ""
                ); // Full name for user

                localMoengage.add_user_attribute("Number", data?.phone ?? "");
                localMoengage.add_user_attribute("Is_Parent", true);
                localMoengage.add_user_attribute("ID", data?.email ?? "");
                localMoengage.add_user_attribute("Location", "");
                localMoengage.add_user_attribute("Academy", "");

                if (data?.children) {
                    // eslint-disable-next-line array-callback-return
                    data?.children.map((bk, key) => {
                        localMoengage.add_user_attribute(
                            `Child${key + 1}_First_Name`,
                            bk?.child_first_name
                        );
                        localMoengage.add_user_attribute(
                            `Child${key + 1}_Last_Name`,
                            bk?.child_last_name
                        );
                        localMoengage.add_user_attribute(
                            `Child${key + 1}_Dob`,
                            null
                        );
                        localMoengage.add_user_attribute(
                            `Child${key + 1}_Gender`,
                            null
                        );
                    });
                }
            });
        }
    }
};

const moengageParentLogout = () => {
    if (window !== undefined) {
      localStorage.removeItem("parentMoengageID");
      // @ts-ignore
      const localMoengage = window?.Moengage;
      if (localMoengage !== undefined) {
        localMoengage.destroy_session();
      }
    }
  };

export {
    moengageTrackEvent,
    moengageParentInit,
    moengageParentLogout
};
