/* eslint-disable react/button-has-type */
import React from 'react';
import Utils from 'src/common/Utils';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import PathRoute from 'src/common/PathRoute';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import { headerActionType } from 'src/redux/actions/actionTypes';

function ListAcademy(props) {
    //! Define
    const { listSite } = props;
    const router = useRouter();
    const dispatch = useDispatch();
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;

    const handleClick = (e, item) => {
        e.preventDefault();

        dispatch({
            type: headerActionType.CHANGE_ACADEMY,
            academy: item,
        });

        dispatch({
            type: headerActionType.CLOSE_LOCATION,
        });
        localStorage.setItem('defaultAcademy', JSON.stringify(item));

        dispatch({
            type: headerActionType.CHANGE_ACADEMY,
            academy: item,
        });

        dispatch({
            type: headerActionType.CLOSE_LOCATION,
        });
        localStorage.setItem('defaultAcademy', JSON.stringify(item));

        localStorage.removeItem('masterData2');
        localStorage.removeItem('childActive2');
        localStorage.removeItem('childNumber');

        router.push(PathRoute.AliasHome(country, item?.ms_alias));
    };

    //! Functions

    //! Render
    return (
        <div className="wrap-row">
            <hr />
            <b>We Make Footballers Academies</b>

            <div className="list-acade">
                {listSite?.map((item) => {
                    return (
                        <button
                            style={{ cursor: 'pointer', display: 'inline' }}
                            onClick={(e) => handleClick(e, item)}
                            key={item.ms_id}>
                            {parse(Utils.checkSubnameListAcademy(item))}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}

export default ListAcademy;
