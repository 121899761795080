/* eslint-disable react/button-has-type */
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import PathRoute from 'src/common/PathRoute';
import Utils from 'src/common/Utils';
import SearchBox from 'src/components/SearchMaps/SearchBox';
import {
    headerActionType,
    siteActionType,
} from 'src/redux/actions/actionTypes';
import { sendGet } from 'src/services/httpMethodPA';
import siteService from 'src/services/siteService';
import ListAcademy from './ListAcademy';
import ListNearbyAcademy from './ListNearbyAcademy';

const inititalValue = {
    searched: false,
    showListAcademy: true,
    listAcademy: [],
    inputSearch: '',
    textResult: '',
};
function LocationModal(props) {
    const route = useRouter();
    const dispatch = useDispatch();
    const headerReducer = useSelector((state) => state.headerReducer);
    const siteReducer = useSelector((state) => state.siteReducer);
    const { listSite } = useSelector((state) => state.listSiteReducer);
    const [visible, setVisible] = useState(false);
    const [searched, setSearched] = useState(inititalValue.searched);
    const [showListAcademy, setShowListAcademy] = useState(
        inititalValue.showListAcademy,
    );
    const [currentCountry, setCurrentCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [modal, setModal] = useState(false);
    const [renderListAcademy, setRenderListAcademy] = useState(false);

    const [listAcademy, setListAcademy] = useState(inititalValue.listAcademy);
    const [inputSearch, setInputSearch] = useState(inititalValue.inputSearch);
    const [textResult, setTextResult] = useState(inititalValue.textResult);
    const [loading, setLoading] = useState(false);
    const [siteUS, setSiteUS] = useState([]);
    const country = useSelector((state) => state.headerReducer.country);

    const allAcademy = listSite.concat(siteUS);
    useEffect(() => {
        getListSite();
    }, []);

    const getListSite = () => {
        if (country === Constants.UK) {
            return getListSiteUS();
        }
        return getListSiteUK();
    };

    const getListSiteUS = async () => {
        const data = await siteService.getListSiteUS();

        if (data?.status === 200 && data?.data?.data?.lstSite) {
            setSiteUS(data?.data?.data?.lstSite);
        }
    };

    const getListSiteUK = async () => {
        const data = await siteService.getListSite();

        if (data?.status === 200 && data?.data?.data?.lstSite) {
            setSiteUS(data?.data?.data?.lstSite);
        }
    };

    useEffect(() => {
        if (headerReducer.type) {
            if (headerReducer.type === headerActionType.CHANGE_LOCATION) {
                // console.log('headerReducer', headerReducer.data);

                if (headerReducer.data) {
                    setTextResult(headerReducer.data.textSearch);
                    setInputSearch(headerReducer.data.textSearch);
                    setListAcademy(headerReducer.data.result);
                    setShowListAcademy(false);
                    setSearched(true);
                }
                setVisible(true);
            }
            if (headerReducer.type === headerActionType.CLOSE_LOCATION) {
                setVisible(false);

                setSearched(inititalValue.searched);
                setShowListAcademy(inititalValue.showListAcademy);
                setListAcademy(inititalValue.listAcademy);
                setInputSearch(inititalValue.inputSearch);
                setTextResult(inititalValue.textResult);
            }
        }
    }, [headerReducer]);

    useEffect(() => {
        if (siteReducer.type) {
            if (
                siteReducer.type ===
                    siteActionType.GET_CURRENT_ACADEMY_SUCCESS &&
                siteReducer.number === 1
            ) {
                setInputSearch(
                    siteReducer.data.ms_address || siteReducer.data.ms_name,
                );
            }
        }
    }, [siteReducer]);

    useEffect(() => {
        setRenderListAcademy(visible);
    }, [visible]);

    const onClickLocation = async (item) => {
        // e.preventDefault();
        try {
            const res = await siteService.getDetailSite({ id: item.ms_id });
            if (res.data.status == 200) {
                const item = res.data?.data?.site || {};
                localStorage.setItem('defaultAcademy', JSON.stringify(item));
                window.location.href = `${PathRoute.AliasHome(
                    country,
                    item.ms_alias,
                )}`;
            }
        } catch (error) {
            console.log(error);
        }
    };

    let headText =
        country === Constants.US
            ? 'Enter Your ZIP code or Current Location to Find Your Nearest Class'
            : 'Enter Your Postcode, Address, Town or Current Location to Find Your Nearest Class';
    if (searched) headText = 'Select Your Local Class';

    const setCurrentLocation = () => {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
        setLoading(true);
        const success = async (pos) => {
            setLoading(false);
            let { latitude, longitude } = pos.coords;

            const getApi = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;

            await sendGet(getApi)
                .then((res) => {
                    if (res.status === 200) {
                        window.localStorage.setItem(
                            'currentLocationLatitude',
                            res.data.latitude,
                        );
                        window.localStorage.setItem(
                            'currentLocationLongitude',
                            res.data.longitude,
                        );

                        setCurrentCountry(res?.data?.countryName);
                        setCountryCode(res?.data?.countryCode);
                        setModal(
                            Utils.checkCountry(res?.data?.countryCode, country),
                        );
                        setInputSearch(
                            res?.data?.locality +
                                ', ' +
                                res?.data?.city +
                                ', ' +
                                res?.data?.countryName,
                        );
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    // setListCourse([]);
                });
        };

        function error(err) {
            alert('Allow this site to access your location', err);
        }

        navigator.geolocation.getCurrentPosition(success, error, options);
    };

    const handleOnNavigate = () => {
        dispatch({
            type: headerActionType.CHANGE_COUNTRY,
            country: Utils.changeLocation(
                countryCode,
                Constants.US,
                Constants.UK,
            ),
        });
        route.push(
            Utils.navigateLocation(
                countryCode,
                PathRoute.Location(Constants.US),
                PathRoute.Location(Constants.UK),
            ),
        );
    };

    const renderLocation = () => {
        return (
            <div className="modalConfirm">
                <div className="textModalConfirm">
                    Do you want to visit the {currentCountry} website?
                </div>
                <div className="countryModalConfirm">{currentCountry}</div>
                <div
                    className="btnModalConfirm"
                    onClick={() => handleOnNavigate()}>
                    Yes
                </div>

                <AiOutlineClose
                    className="closeBtn"
                    onClick={() => setModal(false)}
                />
            </div>
        );
    };

    return (
        <div
            className="location-modal"
            style={{ display: visible ? 'block' : 'none' }}
            onClick={() => {
                dispatch({ type: headerActionType.CLOSE_LOCATION });
            }}>
            <div
                className="modal-content"
                onClick={(e) => {
                    e.stopPropagation();
                    setModal(false);
                    // setLoading(false)
                }}>
                <a
                    href="/#"
                    className="close"
                    onClick={(evt) => {
                        evt.preventDefault();
                        dispatch({ type: headerActionType.CLOSE_LOCATION });
                    }}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{ color: '#EE7925', fontSize: '0.7em' }}
                    />
                </a>
                <div>
                    <div className="wrap-row">
                        <h2>{headText}</h2>
                    </div>
                    {loading && (
                        <div className="loadingSpin">
                            <Spinner
                                animation="border"
                                style={{ width: '2rem', height: '2rem' }}
                            />
                        </div>
                    )}

                    {!searched && (
                        <div className="wrap-row">
                            <button
                                style={{ cursor: 'pointer' }}
                                className="current-location"
                                onClick={() => {
                                    setCurrentLocation();
                                }}
                                disabled={loading}>
                                <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    style={{
                                        color: '#EE7925',
                                        marginRight: 10,
                                    }}
                                />
                                Use current location
                            </button>
                        </div>
                    )}
                    <div className="wrap-row">
                        {searched && (
                            <label className="label-find">
                                Find your nearest class
                            </label>
                        )}

                        <SearchBox
                            isSearch={true}
                            listSite={allAcademy}
                            searched={searched}
                            inputSearch={inputSearch}
                            setShowListAcademy={setShowListAcademy}
                            setSearched={setSearched}
                            setListAcademy={setListAcademy}
                            setInputSearch={setInputSearch}
                            setTextResult={setTextResult}
                            country={country}
                        />
                        {modal && renderLocation()}
                    </div>
                </div>

                {/*<div style={{*/}
                {/*    marginTop: '2rem',*/}
                {/*    fontSize: '15px',*/}
                {/*    color: "#ee7925",*/}
                {/*    textAlign: "center"}}>Unsure which class?*/}
                {/*    <a onClick={() => {*/}
                {/*              dispatch({*/}
                {/*                  type: headerActionType.CLOSE_LOCATION,*/}
                {/*              });*/}
                {/*              route.push(PathRoute.FindClass(country));*/}
                {/*          }}*/}
                {/*      style={{*/}
                {/*        color: '#ee7925',*/}
                {/*        fontSize: '15px',*/}
                {/*        textDecoration: "underline",*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "bold"*/}
                {/*    }}> Use our class finder</a>*/}
                {/*</div>*/}

                {showListAcademy ? (
                    <div className={'search-methods'}>
                        <p className={'label'}>
                            Or use of our other search methods
                        </p>
                        <div className={`list-item`}>
                            <div
                                className={'item'}
                                onClick={() => {
                                    dispatch({
                                        type: headerActionType.CLOSE_LOCATION,
                                    });
                                    route.push(PathRoute.FindClass(country));
                                }}>
                                <img
                                    loading="lazy"
                                    src={'/static-file/images/icon-filter.png'}
                                    alt=""
                                />
                                <p>
                                    Filter class by time <br /> and distance
                                </p>
                            </div>
                            <div
                                className={'item'}
                                onClick={() => {
                                    dispatch({
                                        type: headerActionType.CLOSE_LOCATION,
                                    });
                                    route.push(PathRoute.Location(country));
                                }}>
                                <img
                                    loading="lazy"
                                    src={
                                        '/static-file/images/map-search-icon.svg'
                                    }
                                    alt=""
                                    style={{ padding: '7px' }}
                                />
                                <p>
                                    Search by map and <br /> academy list
                                </p>
                            </div>
                        </div>
                    </div>
                ) : null}

                {!showListAcademy
                    ? renderListAcademy && (
                          <ListNearbyAcademy
                              listAcademy={listAcademy}
                              textResult={textResult}
                              onClickLocation={onClickLocation}
                          />
                      )
                    : renderListAcademy && (
                          <ListAcademy
                              listSite={listSite}
                              onClickLocation={onClickLocation}
                          />
                      )}
            </div>
        </div>
    );
}

export default LocationModal;
