import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PathRoute from 'src/common/PathRoute';
import {
    headerActionType,
    siteActionType,
} from 'src/redux/actions/actionTypes';
import { PopupButton } from '@typeform/embed-react';
import useGetWidth from 'src/hooks/useGetWidth';
import Button from 'src/components/Button';
import Constants from 'src/common/Constants';
import { isEmpty } from 'lodash';

function WeeklTrainingItem(props) {
    const { item, index, site, title, distance } = props;
    const isWithTable = useGetWidth() <= 402;
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    const dispatch = useDispatch();
    const history = useRouter();

    const handleOnClick = () => {
        if (!isEmpty(site)) {
            // return `https://www.parentarea.co/parent/signup`;
            return `https://www.parentarea.co/parent/signup?academyId=${site.pa_academyId}`;
        }
        return 'https://www.parentarea.co/parent/signup';
    };

    const renderButton = () => {
        if (site?.ms_use_typeform === 1) {
            return (
                <PopupButton
                    id={site?.ms_typeform_id}
                    className="book-trial btn-book-trail pdingBoth10 white-hover"
                    size={90}>
                    Try a free session
                </PopupButton>
            );
        }

        return (
            <label
                // className="book-trial"
                style={{
                    cursor: 'pointer',
                    display: 'block',
                    textAlign: 'center',
                }}
                onClick={() => {
                    dispatch({
                        type: siteActionType.SELECT_ACADEMY,
                        data: site,
                    });

                    global.bookTraining = {
                        siteId: site?.ms_id || 0,
                        siteName: site?.ms_name || '',
                        address: '',
                        preDefined: { item },
                    };

                    dispatch({ type: headerActionType.CLOSE_LOCATION });

                    // history.push(PathRoute.BookTrialTrainingSuccess(country));

                    location.replace(
                        PathRoute.BookTrialTrainingSuccessWithAlias(
                            country,
                            site.ms_alias,
                        )
                    )

                }}>
                Try a free session
            </label>
        );
    };

    return (
        <>
            <div>
                <p>
                    {title.indexOf('pa=') !== -1 ? title.substr(0, title.indexOf('pa=')) : title}
                </p>
                <p>{site?.distance?.toFixed(2)} kilometers away</p>
                {item?.map((el, index) => (
                    <div key={index} className="wrap-course">
                        {
                            el?.gender === 0 ? (
                                <div>
                                    <span
                                        className={`w-26`}
                                        style={{
                                            padding: "10px 0px",
                                            display: "flex",
                                            fontWeight: "bold",
                                            color: "rgb(238, 121, 37)",
                                            borderBottom: "4px solid rgb(238, 121, 37)",
                                            marginLeft: "15px",
                                            textAlign: "center",
                                            fontSize: '14px'
                                        }}>
                                        Girls only class
                                    </span>
                                </div>
                            ) : null
                        }
                        <div className="course-odd">
                            <p className="course-time">
                                {' '}
                                {isWithTable
                                    ? el?.day_of_week.slice(0, 3)
                                    : el?.day_of_week}
                                &nbsp;
                                <br />
                                {el?.min_age}-{el?.max_age}{' '}
                                {isWithTable ? 'y.o.' : 'year olds'} <br />
                                {dayjs(
                                    '2021-03-03T' + el?.time_start,
                                ).format('HH:mma')}
                                -
                                {dayjs(
                                    '2021-03-03T' + el?.time_end,
                                ).format('HH:mma')}
                            </p>
                            <div>
                                <div className="paddingBtnSignUp">
                                    {renderButton()}
                                </div>
                                <div className="signUpText">
                                    Ready to book?{' '}
                                    <a
                                        style={{
                                            color: '#ff7531',
                                            fontWeight: 'bold',
                                        }}
                                        href={handleOnClick()}>
                                        SIGN UP
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default WeeklTrainingItem;
