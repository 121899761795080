import {
    faChevronDown,
    faEnvelope,
    faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import avatar from "images/gallery4.jpg";
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs,
} from 'react-google-maps';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import PathRoute from 'src/common/PathRoute';
import Utils from 'src/common/Utils';
import Spinner from 'src/components/Spinner';
import {
    headerActionType,
    siteActionType,
} from 'src/redux/actions/actionTypes';
import WeeklyTrainingItem from './WeeklyTrainingItem';
import useGetWidth from 'src/hooks/useGetWidth';
import { AiOutlineClose } from 'react-icons/ai';

const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => {
        return (
            <GoogleMap
                defaultZoom={12}
                center={{
                    lat: props?.highlight?.ms_addresses
                        ? parseFloat(
                              props?.highlight?.ms_addresses[0]?.ms_latitude,
                          )
                        : parseFloat(props?.highlight?.ms_latitude),
                    lng: props?.highlight?.ms_addresses
                        ? parseFloat(
                              props?.highlight?.ms_addresses[0]?.ms_longitude,
                          )
                        : parseFloat(props?.highlight?.ms_longitude),
                }}>
                {!isEmpty(props) &&
                    props?.highlight?.ms_addresses?.map((e, index) => (
                        <Marker
                            key={index}
                            icon={'/static-file/images/marker.png'}
                            position={{
                                lat: parseFloat(e.ms_latitude),
                                lng: parseFloat(e.ms_longitude),
                            }}
                        />
                    ))}
            </GoogleMap>
        );
    }),
);

function ListNearbyAcademy(props) {
    const siteReducer = useSelector((state) => state.siteReducer);
    const history = useRouter();
    const dispatch = useDispatch();
    const chooseAcademyModal = useRef(null);
    const [lstAcademy, setLstAcademy] = useState(props.listAcademy || []);
    const [highlightAcademy, setHighlightAcademy] = useState(0);
    const [noResult, setNoReSult] = useState(false);
    const [weeklyCourse, setWeeklyCourse] = useState([]);
    const [modal, setModal] = useState(false);
    const isMobile = useGetWidth() <= 768;
    const [currentCountry, setCurrentCountry] = useState('');
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    //! useEffect
    useEffect(() => {
        setLstAcademy(props.listAcademy);
        setNoReSult(isEmpty(props.listAcademy));
    }, [props.listAcademy]);

    useEffect(() => {
        if (!isEmpty(lstAcademy[highlightAcademy])) {
            const listId = lstAcademy[highlightAcademy].ms_addresses
                .map((item) => item.pa_locationId)
                .join(',');

            dispatch({
                type: siteActionType.GET_LIST_COURSE,
                company_id: lstAcademy[highlightAcademy].pa_companyId,
                location_ids: listId,
                course_type: 'course',
            });
        }
        // }
    }, [highlightAcademy, lstAcademy[highlightAcademy]]);

    useEffect(() => {
        if (siteReducer?.type === siteActionType.GET_LIST_COURSE_SUCCESS) {
            if (siteReducer.courseType === 'course') {
                setWeeklyCourse(
                    Utils.convertLocation(
                        lstAcademy[highlightAcademy].ms_addresses,
                        siteReducer.dataCourse,
                    ),
                    // console.log('weekly',weeklyCourse)s
                );
            }
        }
    }, [siteReducer]);
    //! Functions

    const handleOnNavigate = () => {
        localStorage.removeItem('masterData2');
        localStorage.removeItem('childActive2');
        localStorage.removeItem('childNumber');

        if (country === Constants.UK) {
            if (lstAcademy[highlightAcademy]?.country_id === 1) {
                return (
                    setCurrentCountry('United States of America'),
                    setModal(true)
                );
            }
            if (lstAcademy[highlightAcademy]?.country_id === 2) {
                return (
                    dispatch({
                        type: headerActionType.CLOSE_LOCATION,
                    }),
                    history.push(
                        PathRoute.AliasHome(
                            country,
                            lstAcademy[highlightAcademy]?.ms_alias,
                        ),
                    )
                );
            }
        }

        if (country === Constants.US) {
            if (lstAcademy[highlightAcademy]?.country_id === 2) {
                return setCurrentCountry('United Kingdom'), setModal(true);
            }
            if (lstAcademy[highlightAcademy]?.country_id === 1) {
                return (
                    dispatch({
                        type: headerActionType.CLOSE_LOCATION,
                    }),
                    history.push(
                        PathRoute.AliasHome(
                            country,
                            lstAcademy[highlightAcademy]?.ms_alias,
                        ),
                    )
                );
            }
        }
    };

    const handleOnUS = () => {
        if (country === Constants.UK) {
            return (
                dispatch({
                    type: headerActionType.CLOSE_LOCATION,
                }),
                dispatch({
                    type: headerActionType.CHANGE_COUNTRY,
                    country: Constants.US,
                }),
                dispatch({
                    type: headerActionType.CHANGE_ACADEMY,
                    academy: lstAcademy[highlightAcademy],
                }),
                history.push(
                    PathRoute.AliasHome(
                        Constants.US,
                        lstAcademy[highlightAcademy]?.ms_alias,
                    ),
                )
            );
        }
        if (country === Constants.US) {
            return (
                dispatch({
                    type: headerActionType.CLOSE_LOCATION,
                }),
                dispatch({
                    type: headerActionType.CHANGE_COUNTRY,
                    country: Constants.UK,
                }),
                dispatch({
                    type: headerActionType.CHANGE_ACADEMY,
                    academy: lstAcademy[highlightAcademy],
                }),
                history.push(
                    PathRoute.AliasHome(
                        Constants.UK,
                        lstAcademy[highlightAcademy]?.ms_alias,
                    ),
                )
            );
        }
    };

    const renderLocation = () => {
        return (
            <div className="modalConfirm">
                <div className="textModalConfirm">
                    Do you want to visit the {currentCountry} website?
                </div>
                <div className="countryModalConfirm">{currentCountry}</div>
                <div className="btnModalConfirm" onClick={() => handleOnUS()}>
                    Yes
                </div>

                <AiOutlineClose
                    className="closeBtn"
                    onClick={() => setModal(false)}
                />
            </div>
        );
    };

    //! render
    if (isEmpty(lstAcademy) && !noResult) {
        return <Spinner />;
    } else if (isEmpty(lstAcademy) && noResult) {
        return (
            <div className="wrap-row" style={{ paddingBottom: '40px' }}>
                <b>
                    There's no academy nearby your search{' '}
                    <span style={{ color: 'red' }}>
                        {` "` + props.textResult + `"`}
                    </span>
                </b>
            </div>
        );
    }

    const renderAddress = () => (
        <>
            <div className="wrap-contact">
                <a
                    // href="/#"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClickLocation(lstAcademy[highlightAcademy]);
                    }}>
                    <span>Set as default location</span>
                </a>
                <a
                    onClick={() => {
                        dispatch({
                            type: headerActionType.CLOSE_LOCATION,
                            param: lstAcademy[highlightAcademy],
                        });
                        history.push(PathRoute.Contact(country));
                    }}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    Enquiry form
                </a>
                <a href={`tel:${lstAcademy[highlightAcademy].ms_phone}`}>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    Call
                </a>
            </div>

            {lstAcademy.length > highlightAcademy && (
                <div
                    className="more-info"
                    onClick={() => {
                        handleOnNavigate();
                    }}>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                        }}>
                        More infomation
                    </a>
                </div>
            )}
        </>
    );

    // const addressLenght = lstAcademy[highlightAcademy].ms_addresses.length;
    // const newAddress = lstAcademy[highlightAcademy].ms_addresses;
    return (
        <div
            ref={chooseAcademyModal}
            style={{
                paddingBottom: '3rem',
                marginTop: isMobile ? 0 : '2rem',
            }}>
            {modal && renderLocation()}
            {lstAcademy.length > highlightAcademy && (
                <div className="map-view">
                    <div className="main-info wrap-row pding-0">
                        <div className="wrap-address">
                            <h3 style={{ marginTop: 0, fontSize: '23px' }}>
                                {parse(
                                    Utils.checkSubnameListAcademy(
                                        lstAcademy[highlightAcademy],
                                    ),
                                )}
                            </h3>

                            {!isMobile && renderAddress()}
                        </div>
                        {lstAcademy.length > 0 && (
                            <div style={{ height: '80%' }}>
                                <div
                                    className="service"
                                    style={{
                                        padding: 0,
                                        width: '100%',
                                        // marginTop:'3rem',
                                        marginBottom: '3rem',
                                    }}>
                                    <div className="service-offered">
                                        <h3>Services offered:</h3>
                                        <ul>
                                            <li>Weekly training</li>
                                            <li>Holiday Camps</li>
                                            <li>Birthday Parties</li>
                                            <li>1-on-1 training</li>
                                            <li>School Training</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="map">
                                    <MapWithAMarker
                                        googleMapURL={Constants.GOOGLE_MAP_URL}
                                        loadingElement={
                                            <div style={{ height: `100%` }} />
                                        }
                                        containerElement={
                                            <div style={{ height: `100%` }} />
                                        }
                                        mapElement={
                                            <div
                                                style={{
                                                    height: `100%`,
                                                    minHeight: isMobile
                                                        ? 500
                                                        : 800,
                                                }}
                                            />
                                        }
                                        highlight={lstAcademy[highlightAcademy]}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="service">
                        <div className="weekly-training">
                            <h3>Weekly training schedule:</h3>
                            {weeklyCourse &&
                                Object.entries(weeklyCourse).map(
                                    (item, index) => {
                                        return (
                                            <WeeklyTrainingItem
                                                title={item[0]}
                                                item={item[1]}
                                                key={index}
                                                index={index}
                                                site={
                                                    lstAcademy[highlightAcademy]
                                                }
                                            />
                                        );
                                    },
                                )}
                        </div>
                        {isMobile && renderAddress()}
                    </div>
                </div>
            )}

            {/* {lstAcademy.length > 0 && (
                <div className="map-view">
                    <div className="map">
                        <MapWithAMarker
                            googleMapURL={Constants.GOOGLE_MAP_URL}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={
                                <div style={{ height: `100%` }} />
                            }
                            mapElement={
                                <div
                                    style={{
                                        height: `100%`,
                                        minHeight: 200,
                                    }}
                                />
                            }
                            highlight={lstAcademy[highlightAcademy]}
                        />
                    </div>
                    <div className="service">
                        <div className="service-offered">
                            <h3>Services offered:</h3>
                            <ul>
                                <li>Weekly training</li>
                                <li>Holiday Camps</li>
                                <li>Birthday Parties</li>
                                <li>1-on-1 training</li>
                                <li>School Training</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )} */}
            {lstAcademy.length > 0 ? (
                <div className="list-academy">
                    <h2>
                        Other Locations Near{' '}
                        <span style={{ color: '#ff7531' }}>
                            {props.textResult}
                        </span>{' '}
                    </h2>
                    {lstAcademy.map((item, index) => {
                        if (index !== highlightAcademy)
                            return (
                                <div
                                    key={index}
                                    onClick={() => {
                                        chooseAcademyModal.current.scrollIntoView(
                                            {
                                                behavior: 'smooth',
                                            },
                                        );
                                        setHighlightAcademy(index);
                                    }}>
                                    <hr />
                                    <div className="wrap-row other-location">
                                        <div>
                                            <h3>{item.ms_name}</h3>
                                            <label>
                                                {'~'}{' '}
                                                {Utils.showDistance(
                                                    lstAcademy[index].distance,
                                                )}
                                                {` kilometers away`}
                                            </label>
                                        </div>
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            style={{ color: '#EE7925' }}
                                        />
                                    </div>
                                </div>
                            );
                        return null;
                    })}
                    <hr />

                    <div style={{
                        marginTop: '2rem',
                        fontSize: '15px',
                        color: "#ee7925",
                        textAlign: "center"}}>Unsure which class?
                        <a onClick={() => {
                            dispatch({
                                type: headerActionType.CLOSE_LOCATION,
                            });
                            history.push(PathRoute.FindClass(country));
                        }}
                           style={{
                            color: '#ee7925',
                            fontSize: '15px',
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: "bold"
                        }}> Use our class finder</a>
                    </div>
                </div>
            ) : (
                <h2>No other locations available</h2>
            )}
        </div>
    );
}

export default ListNearbyAcademy;
